
/* @import url("https://fonts.googleapis.com/css2?family=Amatic+SC&family=Quicksand&display=swap"); */

.App {
  text-align: center;
  height: 100vh;
  position: relative;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.float-whatsapp {
  position: fixed;
  height: 55px;
  bottom: 40px;
  right: 60px;
  width: 55px;
  background-color: #25d366;
  border-radius: 50%;
  z-index: 100;
}
@media (max-width: 767px) {
  .float-whatsapp {
    right: 30px;
    width: 45px;
    height: 45px;
    bottom: 40px;
  }
}