.rotating-image {
    animation: rotation 14s infinite linear;
    opacity: 0.9
  }
  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  // margin: { xs: 'auto' }, width: { xs: '70%', md: "85%", lg: '90%' }
.levitating-image{
  animation: levitation 2s infinite ease-in-out;
}
@keyframes levitation {
  0% { transform: translate(0,  -15px); }
  50%  { transform: translate(0, 10px); }
  100%   { transform: translate(0, -15px); }   
}

.levitating-arrow{
  animation: levitation-arrow 2s infinite ease-in-out;
}
@keyframes levitation-arrow {
  0% { transform: translate(-5px,  0); }
  50%  { transform: translate(5px, 0); }
  100%   { transform: translate(-5px, 0); }   
}

.home-head-image{
  max-height: auto;
  width: 100%;
  margin: auto;
  text-align: center;
  overflow: hidden;

  @media (max-width: 767px) {
    max-width: 400px;
    padding: 2rem;
  }
  @media (min-width: 768px) {
    max-width: 550px;
  }
}

.page-content{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  // background-color: RGB(238, 235, 230);
  @media (max-width: 767px) {
    justify-content: center;
  }
  
  .page-content-image{
    flex: 1 1 calc(50% - 10px); /* 10px is the margin between the columns */
    margin: 5px;
    max-height: auto;
    justify-content: center;

    @media (max-width: 767px) {
      max-width: 300px;
      padding: 2rem;
    }
    @media (min-width: 768px) {
      max-width: 400px;
    }
    margin: 1rem; 
  }

  .page-content-text{
    flex: 1 1 calc(50% - 10px); /* 10px is the margin between the columns */
    margin: 5px;
    @media (max-width: 767px) {
    margin: 0.5rem;
    padding: 0.5rem;
    text-align: center;
    }
    @media (min-width: 768px) {
    margin: 1rem;
    padding: 1rem;
    text-align: left;
    }
  }
}