$bgcolor: #1F2126;
$bgsecondary: #2f3134;
$bgdark: #1a1818;
$primarytext: #F0F0F0;
$secondarytext: #B1B1B1;
$accentcolor: #FF7F00;
$successcolor: #3CC13B;
$warningcolor: #F5A623;
$errorcolor: #F44336;
$illustration: #b08450;
$bgcolor-light1: #f0efe9;
$bgcolor-light2: #FFFFFF;

.active{
    color: $accentcolor !important;
}

.nav-link{
    text-decoration: none;
    color: $primarytext;
    font-weight: 600;
    font-size: 16px;
    margin: auto 1rem;
    background: transparent;
}

.nav-link:hover{
    color: $accentcolor;
}

.action-circle-button{
    cursor: pointer;
    a{
        border: 1px solid $accentcolor;
        color: #FF7F00;
    }
}


.info-card{
    border: 1px solid $bgsecondary;
    border-radius: 0.3rem;
    padding: 1.5rem;
    cursor: pointer;
    min-height: 300px;
    max-width: 400px;
    margin: auto;
    .info-card-body{
        @media (max-width: 767px) {
            align-items: center;
            text-align: center;
        }
        @media (min-width: 768px) {
            align-items: left !important;
            text-align: left;
        }
        .img-container{
            @media (max-width: 767px) {
                max-width: 132px;
            }
            @media (min-width: 768px) {
                max-width: 158px;
            }
        }
        img{
            width: 100%;
        }
    }
}
.info-card:hover{
   background-color: $bgsecondary;
}

.footer{
    padding:2rem;
    background-color: $bgdark;
    overflow: hidden;
    position: absolute;
    width: 100%;

    .content-section{
        text-align: left;
        z-index: 1;

        .content-title{
            position: relative;
        }

        .content-title::before{
            content: " ";
            position: absolute;
            bottom: 0;
            left: 0;
            height: 2px;
            width: 60%;
            background: linear-gradient(to right, $warningcolor 50%, transparent 50%);
        }
    }
    .img-container{
        opacity: 0.3;
        overflow: hidden;
        z-index: 0;
        position: absolute;

        img{
            max-width: 95%;
            height: auto;
            object-fit: cover;
            object-position: center;
        }
    }
}

.breadcrumb{
    text-align: center;
    @media (max-width: 767px) {
        padding: 2rem;
    }
    @media (min-width: 768px) {
        padding: 3rem;
    }

    .img-container{
        opacity: 0.3;
        overflow: hidden;
        z-index: 0;
        position: absolute;

        img{
            max-width: 95%;
            height: auto;
            object-fit: cover;
            object-position: center;
        }
    }
}

.blog-card{
    padding: 1rem;
    margin: 1rem;
    position: relative;
    max-width: 386px;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    cursor: pointer;

    .img-container{
        max-width: 365px;
        margin-bottom: 2rem;
        position: relative;
    }
    .blog-card-date{
        position: absolute;
        font-weight: 500;
        bottom: -19px;
        right: 35px;
        background: $accentcolor;
        color: var(--al-white);
        padding: 13px 10px;
        display: inline-block;
        text-align: center;
        min-height: auto;
        min-width: 150px;
        text-transform: uppercase;
    }
    .blog-card-date::before{
        content: '';
        position: absolute;
        left: auto;
        right:100%;
        top: 0;
        bottom: 0;
        border-top: 24px solid transparent;
        border-bottom: 24px solid transparent;
        border-right: 15px solid $accentcolor
    }
    .blog-card-date::after{
        content: '';
        position: absolute;
        left: 100%;
        top: 0;
        bottom: 0;
        border-top: 24px solid transparent;
        border-bottom: 24px solid transparent;
        border-left: 15px solid $accentcolor
    }
}

.horoscope-detail{
    padding:1rem;
    
}

.switch-theme-section{
    background-color: $bgcolor-light1;
    color: #111111;
    @media (max-width: 767px) {
        padding-top: 1rem;
    }
    @media (min-width: 768px) {
        padding-top: 2rem;
    }

}